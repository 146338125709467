import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, doc, query, where } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';


// Actions
import { addLike, deleteProject } from '../../actions/projectActions';
import { getUserById } from '../../actions/authActions';
import { createChat } from '../../actions/chatActions';
import { toggleAuthModal } from '../../actions/navActions';
import { setAlert } from '../../actions/alertActions';

// components - material UI
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Post from '../Post/Post';
import Icon_Block from '../../pages/GamePage/Icon_Block';

import ImageBlock from '../common/ImageBlock';

// Icons - material UI
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import RepeatIcon from '@material-ui/icons/Repeat';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Fragment } from 'react';
import Verification_Badge from '../common/Verification_Badge';

const Detail = ({
        detail_Obj,
        auth, 
        setAlert,
        addLike,
        deleteProject,
        getUserById,
        createChat,
        toggleAuthModal,
        toggleCommentModal,
        image,
        history,
        isTablet,
        commentList,
        likeList,
        setScrollDirection,
        setOldScrollY,
        oldScrollY,
        page_filter
    }) => {
        
        // Tracks the like status of post
        const [liked, setLiked] = useState(false);
        // Flag for checking if like is properly updated
        const [checkLike, setCheckLike] = useState(false);

        // --- Convert DraftJS text to HTML text

        // Tracks whether the text has been converted
        const [gotText, setGotText] = useState(false);
        // Stores the converted text
        const [textState, setTextState] = useState(null);

        // Check if post has image - for font size reasons
        const [hasImg, setHasImg] = useState(false);
        // Flag for checking if hasImg is properly updated
        const [checkHasImg, setCheckHasImg] = useState(false);

        // Toggle Dropdwon
        const [dropdown, setDropdown] = useState(false);
        const [shareDropdown, setShareDropdown] = useState(false);

        // Stores the height of a toggle menu
        const [menuHeight, setMenuHeight] = useState(null);

        // Modal toggles
        const [deleteModal, setDeleteModal] = useState(false);

        // Tracks the status of copying
        const [copied, setCopied] = useState(false);
        
        // Controls the visibility of a copied modal
        const [copiedModal, setCopiedModal] = useState(false);

        useEffect(() => {

            // Set a timeout to hide the copied modal after 2 seconds
            setTimeout(() => {
                setCopiedModal(false);
            }, 2000);

        }, [copiedModal]);

        useEffect(() => {

            // Set a timeout to hide the share dropdown and reset the copied flag after 1 second
            setTimeout(() => {
                setShareDropdown(false);
                setCopied(false);
            }, 1000);
        }, [copied]);

        const handleLike = (detail_Obj_Id) => {

            // If the user is authenticated, add a like to the detail_Obj and toggle the liked state
            if(auth.user) {
                addLike(null, detail_Obj_Id, auth.user, detail_Obj);
                setLiked(!liked);
            } else {

                // If the user is not authenticated, fetch the user by ID and toggle the authentication modal
                getUserById(detail_Obj.user._id);
                toggleAuthModal('project like');
            }
        }

        // Redirect to the create comment page for the current detail_Obj
        const redirectComment = () => {
            history.push(`/create/comment/${detail_Obj._id}`);
        }

        // Copy Modal logic

        const toggleCopiedModal = () => {

            // --- Close other dropdowns and set the copied alert message

            setDropdown(false);
            setShareDropdown(false);
            
            // setCopiedModal(!copiedModal);
            setAlert('Copied to clipboard', `${!auth.loading && auth.isAuthenticated ? 'okay' : 'success'}`);
        }
        
        // Check if the authenticated user exists and the checkLike flag is false
        if(auth.user && !checkLike) {

            // Filter the likes array of the project to check if the authenticated user has liked the project
            if(detail_Obj.likes.filter(like => like.user._id.toString() === auth.user._id).length > 0){

                // If the authenticated user has liked the project, set the liked state to true
                setLiked(true);
            }
            
            // Set the checkLike flag to true to indicate that the check has been performed
            setCheckLike(true);
        }  

        return (
            <Fragment>

                <div className="postPage">

                    {/* Detail Post Body - bottom */}
                    <div className="postPage__body">

                        <div className="gamePage_header">
                        
                            <Icon_Block detail_Obj={detail_Obj} page="detail"  />

                            {/* Display the user's name & username */}
                            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                <p style={{fontSize: '14px', color: 'rgb(83, 100, 113)'}}>CURRENTLY VIEWING</p>
                                <h2 style={{fontSize: '22px', marginTop: '5px'}}>
                                    {detail_Obj.title}
                                </h2>

                                {detail_Obj.short_description && (
                                    <p style={{color: 'rgb(83, 100, 113)', marginTop: '10px'}}>{detail_Obj.short_description}</p>
                                )}
                                
                            </div>


                        </div>

                        

                        <div className="gamePage__actions">
                            <div>

                                {/* Comment button */}
                                <button 
                                    onClick={() => {
                                        if(auth.user) {
                                            if(isTablet) {
                                                redirectComment()
                                            } else {
                                                toggleCommentModal()
                                            }
                                        } else {
                                            getUserById(detail_Obj.user._id);
                                            toggleAuthModal('project comment');
                                        }
                                    }}                   
                                    className="secondaryBtn post"
                                >
                                    <div>
                                        {/* Btn Icon */}
                                        <ChatBubbleOutlineIcon fontSize="small" />
                                        {/* Btn Text */}
                                        <span>Comment</span>
                                    </div>
                                </button>
                            </div>
                            <div className={liked ? "active" : "" } onClick={() => handleLike(detail_Obj._id)}>

                                {/* Like button */}
                                <button className={liked ? "secondaryBtn post active" : "secondaryBtn post like" }>
                                    <div>

                                        {/* Render the like icon based on whether the post is liked */}
                                        {liked ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}

                                        {/* Btn Text w/ like qty */}
                                        <span>{liked ? 'Saved!' : 'Save'} {detail_Obj.likes.length > 0 && (
                                            <span style={liked ? {display:'none'}: {fontSize:'11px'}}>({detail_Obj.likes.length})</span>
                                        )}</span>
                                    </div>
                                </button>
                            </div>
                        
                            
                            <div style={{position: 'relative'}}>

                                {/* Share button */}
                                <button onClick={() => setShareDropdown(!shareDropdown)} className="secondaryBtn post share">
                                    <div>
                                        {/* Btn Icon */}
                                        <ShareIcon fontSize="small" />
                                        {/* Btn Text */}
                                        <span>Share</span>
                                    </div>
                                </button>

                                {/* Share btn Dropdown menu */}
                                <div className={shareDropdown ? "edit-dropdown active" : "edit-dropdown"} style={{height: menuHeight}}>
                                    <div className="menu">

                                        {/* Copy post link to clipboard */}
                                        <CopyToClipboard 
                                            text={`${process.env.REACT_APP_DOMAIN}/post/${detail_Obj._id}?redirect=true`}
                                            onCopy={toggleCopiedModal}
                                        >
                                            <div className="menu-item">
                                                {!copied ? (
                                                    <Fragment>
                                                        <LinkIcon />
                                                        Copy link
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <CheckIcon />
                                                        Copied!
                                                    </Fragment>
                                                )}
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>

                                {/* Hidden Overlay for share btn dropdown */}
                                <div
                                    className={`hidden-overlay ${shareDropdown ? "show" : ""}`}
                                    onClick={() => setShareDropdown(!shareDropdown)}
                                />
                            </div>
                        </div>

                        {/* Display the post images if available */}
                        {/* <ImageBlock detailPost={post} setHasImg={setHasImg} setCheckHasImg={setCheckHasImg} checkHasImg={checkHasImg} /> */}

                        {/* Render the "Play Game" button */}
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '1rem'}}>
                            <Button onClick={() => window.open(detail_Obj.view_link, '_blank')} variant="outlined" className="sidebar__tweet black" fullWidth>View Project</Button>
                        </div>

                        {detail_Obj.banner_img && (
                            <div>
                                <img style={{width: '100%'}} src={detail_Obj.banner_img} />
                            </div>
                        )}
                        
                        <div className="feed_headerTabs">
                    
                            {/* <Link to={`?show=about`}>
                                <div className={`${(page_filter === null || page_filter === undefined || page_filter === 'about') && 'active'}`}>
                                    <h3>About</h3>
                                    <div className="block__underline"></div>
                                </div>
                            </Link> */}

                            <Link style={{flex: 1}} to={`?show=comments`}>
                                <div className={`${(page_filter === null || page_filter === undefined || page_filter === 'comments') && 'active'}`}>
                                    <h3>Comments</h3>
                                    <div className="block__underline"></div>
                                </div>
                            </Link>
                            
                        </div>

                    </div>
                </div>

                {/* Copied Link Modal */}
                <ModalContainer show={copiedModal} onClose={toggleCopiedModal} white>
                    <Modal border>

                        {/* Modal Title */}
                        <div>
                            <h2>Link Copied!</h2>
                        </div>

                        {/* Modal Description */}
                        <p>You can share this link with anyone.</p>
                        
                        {/* Modal Actions */}
                        <Button 
                            onClick={toggleCopiedModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost"
                            fullWidth
                        >
                            Close
                        </Button>
                    </Modal>
                </ModalContainer>
            </Fragment>
        )
}

Detail.propTypes = {
    setAlert: PropTypes.func.isRequired, // Function prop to set an alert
    addLike: PropTypes.func.isRequired, // Function prop to add a like to a post
    deleteProject: PropTypes.func.isRequired, // Function prop to delete a post
    getUserById: PropTypes.func.isRequired, // Function prop to retrieve user data by ID
    createChat: PropTypes.func.isRequired, // Function prop to create a chat conversation
    toggleAuthModal: PropTypes.func.isRequired, // Function prop to toggle the authentication modal
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
}

const mapStateToProps = state => ({
    
    // Mapping the auth state from the Redux store to the 'auth' prop
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setAlert, 
    addLike, 
    deleteProject, 
    getUserById, 
    createChat, 
    toggleAuthModal 
})(Detail);
