import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Blurhash } from 'react-blurhash';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, doc, query, where } from 'firebase/firestore';

// Actions

// Components

// Icons = material UI

// Modal

const Project_Block = ({ 
    detail_Obj,
    page
}) => {

    return (

        <div onClick={() => window.location = `/project/${detail_Obj._id}`} style={{padding: '10px', cursor: 'pointer', backgroundColor: '#f4ece8', borderRadius: '15px', marginBottom: '10px', textAlign: 'center', color: '#777', padding: '20px'}}>
            <div>
                <img style={{height: '60px', width: '60px', borderRadius: '20px'}} src={detail_Obj.icon_link} />
            </div>
            <h2 style={{fontSize: '26px', marginTop: '10px'}}>{detail_Obj.title}</h2>
            <br />
            <span style={{fontSize: '16px'}}>
                {detail_Obj.short_description}
            </span>
        </div>
    )
}


Project_Block.propTypes = {

}

// Map these states from Redux store to the component props
const mapStateToProps = state => ({

})

// Connect the Project_Block component to the Redux store and export it
export default connect(mapStateToProps, { 

})(Project_Block);

