import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// components - material UI
import { Button } from '@material-ui/core';

// icons - material UI
import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Projects_Page = ({ 
    handleSlideMenu, 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    useEffect(() => {

         // Add event listener for window resize
         window.addEventListener('resize', () => handleWindowSizeChange());

         // Cleanup the event listener when the component unmounts
         return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Help Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header more_page_header">
                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                    {isMobile && badgeValue > 0 ? (
                        <span className="feed-header-badge defaultBadge">
                            {badgeValue}
                        </span>
                    ) : null}
                </div>

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        About Me
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body terms">
                <div className="terms-of-service" style={{paddingTop:'20px'}}> 
                    <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextPrimary">Last updated: October 23, 2024</p>
                    <div>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            Hi! Thank you for visiting my site. I'm Tunde. :)
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            I spend my days building software projects, for small-to-medium size businesses. I previously worked as a social media manager for companies like <a href="https://www.pangea.app/" target='_blank'>Pangea.app</a> (in two months grew their audience from 1.5k to 33.2k followers on TikTok) and <a href="https://theandibrand.com/" target='_blank'>The Andi Brand</a> (as a content creator & editor).
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            But as a long-time science nerd 🤓, I spend a lot of time researching ideas & launching experiments. Since teaching myself to code as a kid, I've built many big projects (<a href="https://www.campusbuysell.com/home" target='_blank'>CampusBuySell</a> is the most popular!), wrote a book on creative thinking, pitched 87 investors, and started my own podcast.
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            My latest project is called Campus Buy Sell, which helps college students buy & sell stuff with each other. The community is ~2k people strong!
                        </p>
                        {/* <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            This all started 8 years ago, when I was unhappy with my life and decided to design a new one. Since then, I’ve worked remotely while traveling the world. I share much of my life in the open, although it’s often a (very) delayed feed. 🙃
                        </p>
                        <ol style={{paddingLeft:'0.7em'}}>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                Here are a few other fun highlights:
                            </p>
                            <ul style={{listStyleType: 'disc'}}>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    Occasionally my Twitter addiction results in someone famous following me. Last month it was Hannibal Buress.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    I wrote my book in 7 weeks and has now sold over $250k.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    Way back when, I did my degree in chem eng.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    I've been known to sleep eat. Yes, it’s a thing.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    I’ve visited over 50 countries.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    I love spreadsheets, electric bikes, flying, untranslatable wods, and dill pickles!
                                </li>
                            </ul>
                        </ol> */}
                    </div>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default Projects_Page;
