import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
                <h1 id="campus-buy-sell">Campus Buy Sell</h1>
                <h2 id="about-the-project">About the Project</h2>
                <p>Campus Buy Sell is the simplest way to Buy/Sell on campus. Browse tons of great items nearby, including furniture, clothes, and electronics, and buy from your classmates. Instantly message sellers securely on the website to negotiate the price and set up a time to meet. Check out people’s profiles to see who they are and what they are selling. Sell something of your own by simply taking a picture with your phone. Posting takes less than 30 seconds! Join thousands of students on the largest group chat for students to buy and sell on campus.</p>
                <h2 id="table-of-contents">Table of Contents</h2>
                <ul>
                <li><a href="#introduction">Introduction</a></li>
                <li><a href="#features">Features</a></li>
                <li><a href="#business-one-pager">Business One-Pager</a></li>
                </ul>
                <h2 id="introduction">Introduction</h2>
                <p>Welcome to the Campus Buy Sell project! This platform provides the simplest way to buy and sell on campus, connecting students for efficient and secure transactions. Built using React, Firebase, Algolia, Stripe, Google API, and other technologies.</p>
                <h2 id="features">Features</h2>
                <ul>
                <li><strong>Real-time Communication</strong>: Utilizes Firebase snapshots for instant messaging.</li>
                <li><strong>Engagement Features</strong>:<ul>
                <li>Like, comment, and share posts.</li>
                <li>Save posts for later reference.</li></ul></li>
                <li><strong>Media Uploads</strong>: Allows users to upload photos using Firebase Storage.</li>
                <li><strong>Notification System</strong>: Incorporates a notification system for updates and alerts.</li>
                <li><strong>Automated Email System</strong>: Utilizes Firestore triggers for an automated email system for outbound notifications.</li>
                <li><strong>Search Functionality</strong>: Implements Algolia for efficient and powerful search capabilities.</li>
                <li><strong>E-commerce Integration</strong>: Utilizes Stripe for secure e-commerce transactions.</li>
                <li><strong>Location Tracking</strong>: Integrates Google API for location tracking.</li>
                <li><strong>Domain-Based Dynamic Content</strong>: Dynamically renders content based on the domain name for deployment across various domains.</li>
                </ul>
                <h2 id="business-one-pager">Business One-Pager</h2>
                <p><strong>Who are we?</strong></p>
                <p>Campus Buy Sell is an online “Buy, Sell, Trade” group chat for people at the University of Oklahoma.</p>
                <p><strong>Problem?</strong></p>
                <p>Colleges do not provide a place for students to exchange goods with other students.</p>
                <p><strong>Solution?</strong></p>
                <ul>
                <li>Public, online group chat in the form of a .com<ul>
                <li>…where students on campus can communicate with each other</li></ul></li>
                <li>Rules for posting:<ul>
                <li>Must be related to buying, selling, or trading goods.</li>
                <li>Must be a student, faculty, or alumni of the university.</li></ul></li>
                </ul>
                <p><strong>Basic Info:</strong></p>
                <ul>
                <li><strong>Industry</strong>: Resale &amp; Q-commerce</li>
                <li><strong>Business Model</strong>: Ads &amp; Sales Commission</li>
                <li><strong>Business Type</strong>: C2C &amp; B2C </li>
                <li><strong>Launch Date</strong>: Aug. 22, 2022</li>
                <li><strong>Links</strong>: <a href="https://www.oubuysell.com/home">Website</a> | <a href="https://www.instagram.com/oubuysell/">Instagram</a> | <a href="https://twitter.com/oubuysell">Twitter</a></li>
                </ul>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;